.dashborad-header-box {
  background-color: #1c1c1c;
  border-radius: 16px;
  display: flex;
  align-items: center;
  height: 124px;
  padding: 0px 20px;
}
.dashborad-header-box > div {
  margin-left: 20px;
}
.dashborad-header-ttile {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
}
.dashborad-header-value {
  background: -webkit-linear-gradient(#ff2957, #ff7357) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-size: 28px;
  font-weight: 600;
  margin-top: 5px;
}
.dashboard-balance-box {
  background-color: #1c1c1c;
  border-radius: 16px;
  padding-top: 20px;
}
.dashboard-balance-title {
  color: #a1a2af;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
.dashboard-balance-price {
  color: #ffffff;
  font-family: "Inter";
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin-top: 15px;
}
.dashboard-table {
  background-color: #1c1c1c;
  border-radius: 16px;
  margin-top: 30px;
}
.dashboard-table-header-title {
  background: -webkit-linear-gradient(#ff2957, #ff7357) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.dashboard-table-text {
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.table-cell {
  border: none !important;
  border-top-width: 1px !important;
  border-top-style: solid !important;
  border-top-color: #080808 !important;
}
.dashboard-action-btn {
  background-color: #031100 !important;
  border-radius: 4px !important;
  height: 32px !important;
  width: 32px !important;
  min-width: 32px !important;
  min-height: 32px !important;
}
.dashboard-portfolio-btn {
  background-color: transparent !important;
  padding: 0px !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
}
.dashboard-portfolio-btn > img {
  margin-left: 5px;
}
.dashboard-top-users-box {
  background-color: #1c1c1c;
  border-radius: 16px;
  padding: 20px;
  margin-top: 25px;
}
.dashboard-top-users-box > p {
  color: #ffffff;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}
.top-user-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}
.top-user-card > p {
  color: #ffffff;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
}
.top-user-card > div {
  display: flex;
  align-items: center;
}
.top-user-card > div > div {
  margin-left: 10px;
}
.top-user-card-name {
  color: #ffffff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
}
.top-user-card-username {
  color: #a1a2af;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 400;
  margin-top: 3px;
}

.dashboard-view-all-btn {
  background-color: #2b2b2b !important;
  width: 100% !important;
  height: 39px !important;
  border-radius: 12px !important;
  margin-top: 20px !important;
  color: #ffffff !important;
  font-family: "Inter" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}

@media only screen and (max-width: 1000px) {
    .dashborad-header-ttile {
        font-size: 16px;
      }
      .dashborad-header-value {
        font-size: 20px;
      } 
}