.deposites-summary-box {
  background-color: #1c1c1c;
  border-radius: 16px;
  margin-bottom: 30px;
}
.deposites-summary-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #2d3530;
}
.deposites-summary-box-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}
.deposites-summary-box-header-text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
.deposites-summary-box-chart{
  padding: 15px;
  margin-top: 10px;
}
.deposits-table-footer{
  background-color: #282828 !important; 
  border-radius: 0px 0px 16px 16px !important;
}
.deposits-table-footer-title{
  color: #FEFEFE;
font-size: 16px;
font-weight: 500;
width: 220px;
}
.deposits-table-footer-price{
  color: #FEFEFE;
font-size: 16px;
font-weight: 500;

}
.css-nx7hv4-MuiTableCell-root{
  border-bottom-width: 0px !important;

}
@media only screen and (max-width: 600px) {
  .deposites-summary-box-title {
    font-size: 16px;
  }
  .deposites-summary-box-header-text {
    font-size: 12px;
  }
}