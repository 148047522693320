.settings-main {
  background-color: #1c1c1c99;
  border-radius: 4px;
  padding: 20px 30px;
}
.settings-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.settings-header-profile {
  width: 120px;
  height: 120px;
  border-radius: 100px;
  margin-right: 20px;
  position: relative;
  min-width: 120px;
  min-height: 120px;
}
.settings-header-profile > img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}
.settings-user-name {
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
}
.settings-user-email {
  color: #d3d3d3;
  font-size: 16px;
  font-weight: 400;
  margin-top: 5px;
}

.attachments-button {
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  background-image: linear-gradient(to right, #ff7357, #ff2957) !important;
  height: 32px;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 32px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.setting-from-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}
.setting-from-header > p {
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
}
.setting-edit-btn {
  background-image: linear-gradient(to right, #ff7357, #ff2957) !important;
  height: 48px !important;
  border-radius: 100px !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  width: 101px !important;
  min-width: 101px !important;
}
.setttings-input-main {
  border: 1px solid #2d3530;
  background-color: #000000;
  height: 48px;
  border-radius: 4px;
  padding: 0px 15px;
  margin-top: 10px;
}
.setttings-input-main > input {
  color: #757575;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 100%;
}
.setttings-input-title {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  margin-top: 15px;
}
.setttings-from {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.setttings-from > div {
  width: 49%;
}
.setting-change-password-btn {
  margin-top: 30px !important;
  padding: 0px !important;
  background-color: transparent !important;
  background: -webkit-linear-gradient(#ff2957, #ff7357) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
}
@media only screen and (max-width: 600px) {
  .setting-from-header > p {
    font-size: 16px;
  }
  .setting-edit-btn {
    height: 35px !important;
    font-size: 12px !important;
    width: 80px !important;
    min-width: 80px !important;
  }
  .settings-header-profile {
    width: 90px;
    height: 90px;
    min-width: 90px;
    min-height: 90px;
  }
  .settings-user-name {
    font-size: 18px;
  }
  .settings-user-email {
    font-size: 13px;
  }
  .setttings-from > div {
    width: 100%;
  }
}
